<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <div>
      <div class="header-img">
        <div class="title-img">
          <img src="./../static/businessRegistrationSuccess.png" alt="">
        </div>
      </div>
      <div class="SignUpForms">
        <div class="SignUpForms-box w1300">
          <div style="display:flex;justify-content: center;align-items: center;flex-direction: column;">
            <div><img src="./../static/success.png" alt=""></div>
            <div style="font-size: 20px;color: rgba(32, 35, 50, 1);margin-top:24px;">提交成功</div>
            <div style="font-size:14px;color: rgba(79, 84, 112, 1);margin-top:20px;">人社部门审核中，待审核成功后，可进行后续投保操作！</div>
            <div class="btn" @click="looking">查看个人中心</div>
          </div>
        </div>
      </div>
    </div>
    <MyFooter />
  </div>
</template>
<script setup>
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
let router = useRouter();
let looking = () => {
  router.push("/personalCenter")
  // const currentUrl = window.location.href;
  // const parts = currentUrl.split('/');
  // const baseUrl = `${parts[0]}//${parts[2]}`;
  // window.open(`${baseUrl}` + '/' + `personalCenter`);
  localStorage.setItem('active', '1');
}
</script>
<style scoped>
.btn {
  border-radius: 4px;
  background: linear-gradient(
    135deg,
    rgba(31, 112, 247, 1) 0%,
    rgba(85, 155, 254, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 16px 7px 16px;
  box-sizing: border-box;
  color: #fff;
  margin-top: 30px;
  cursor: pointer;
}
.SignUpForms {
  width: 100%;
  height: calc(100vh - 580px);
  background: rgb(246, 247, 250);
}
.SignUpForms-box {
  padding-top: 50px;
  box-sizing: border-box;
}
img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.header-img {
  width: 100%;
  height: 340px;
  background: url("./../static/banner-pub.png") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}
.title-img {
  width: 360px;
  height: 119px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -75px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.05),
    0px 0px 8px 2px rgba(0, 82, 217, 0.1);
  padding: 20px 0;
  box-sizing: border-box;
}
.w1300 {
  width: 1300px;
  margin: 0 auto;
}
</style>
